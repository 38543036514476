import React from 'react'
import * as d3 from 'd3'

const margin = { top: 0, right: 10, bottom: 5, left: 10 }

class ElevationPlot extends React.Component {
	state = {
		xScale: d3.scaleLinear()
			.domain([ 0, this.props.data[this.props.data.length - 1][0] ])
			.range([ margin.left, this.props.width - margin.right ]),

		yScale: d3.scaleLinear()
			.domain([d3.min(this.props.data, d => d[1]) - 15, d3.max(this.props.data, d => d[1])]).nice()
			.range([ this.props.height - margin.bottom, margin.top ]),

		line: d3.line()
			.defined(d => !isNaN(d[1]))
			.x(d => this.state.xScale(d[0]))
			.y(d => this.state.yScale(d[1]))
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let { xScale, yScale } = prevState;

		xScale.range([ margin.left, nextProps.width - margin.right ])
		yScale.range([ nextProps.height - margin.bottom, margin.top ])

		return { ...prevState, xScale, yScale };
	}

	render () {
	let boxWidth = 0
	let labelWidth = 60

	let border = [
		`M${this.props.width - boxWidth * 2},${this.props.height - boxWidth / 2}`, // move to bottom right
		`H${this.props.width / 2 + labelWidth / 2}`, // horizontal to right edge of label
		`M${this.props.width / 2 - labelWidth / 2},${this.props.height - boxWidth / 2}`, // move to left edge of label
		`H${boxWidth * 2}`, // horizontal to bottom left
	].join('')

	if (this.props.top) {
		border = null
	}

	return (
			<div className="ElevationPlot">
				<svg width={this.props.width} height={this.props.height}>
					{border && (
						<g>
							<path fill='none' strokeWidth='0.5' d={border} />
						</g>
					)}
					<g>
						<path fill='none' strokeWidth='2.5' strokeLinejoin='round' strokeLinecap='round' d={this.state.line(this.props.data)} />
					</g>
					{border && (
						<g>
							<text style={{fontSize: '0.8em'}} textAnchor='middle' x='50%' y={this.props.height}>{(this.props.distance / 1000).toFixed(1)} km</text>
						</g>
					)}
				</svg>
			</div>
		)
	}
}

export default ElevationPlot
