import React, { useState, useEffect } from 'react'
import BackgroundShield from '../shield'

// const blurbTitleTrimestreFemenino = "Montaña se escribe con \"A\""
// const blurbTrimestreFemenino = "Montaña, mochila, roca, aventura, compañía, alegría, fuerza. Palabras que terminan en “A”, como Alicia, Verónica, Natalia, Eva, Silvia y hasta dieciséis socias que nos van a llevar por la montaña este trimestre para comprobar que el deseo de sentir la naturaleza, de superar retos o la capacidad de esfuerzo no tienen nada que ver con el género."

// const blurbTitleBajoCuarentena = "bajo cuarentena"
// const blurbBajoCuarentena = "La junta directiva del GMT reunida en la tarde del jueves día 12 de marzo, ha decidido suspender todas las actividades del grupo, salidas, proyecciones, eventos, aperturas y reuniones en general previstas y organizadas a instancia de esta dirección. Esto afecta tanto a la proyección del viernes 13, la salida del domingo 14, fin de semana en Somiedo (21 y 22), cursos de formación y salida (28 y 29) del presente mes."

// const blurbTitle = "en un momento crítico";
// const blurbHtml = "Se suspenden todas las actividades hasta nuevo aviso.<br/><br/>P.D. Talonario n.3 lotería Navidad 2020 que se celebrará el próximo 22 de diciembre, queda anulado por extravío (papeletas numeradas 51 al 75).";

// const blurbTitle = "siempre aparecerá una luz"
// const blurbHtml = "...que nos devuelva a la montaña. Dentro de poco volveremos a caminar juntos. ¡¡Feliz Año 2021!!"

const blurbTitle = null;
const blurbHtml = null;
const blurbActiveOnLoad = false;

export default function () {
	const [blurbActive, setBlurbActive] = useState(blurbActiveOnLoad)
	const [videoSrc, setVideoSrc] = useState('videos/video.mp4')

	useEffect(() => {
	  setVideoSrc( document.gmt.videoSrc );
	}, [])

	function setBlurbActive_true() {
		setBlurbActive(true)
	}

	function setBlurbActive_false() {
		setBlurbActive(false)
	}

	const blurbActiveStyles = {
		"BlurbActive": blurbActive === true,
		"BlurbInactive": blurbActive === false
	}

	const containerClassNames = ["PortadaContainer", "BackgroundContainer"];
	Object.keys(blurbActiveStyles).forEach(function (className) {
		if (blurbActiveStyles[className]) {
			containerClassNames.push(className);
		}
	})

	return (
		<div className={containerClassNames.join(' ')}>
			{videoSrc && 
				<video className="BackgroundVideo" autoPlay muted loop playsInline src={videoSrc}></video>
			}
			<div className="BackgroundImage"></div>
			<div className="BackgroundOverlay"></div>
			<div className="BackgroundHeadingAndSlogan">
				<div className="BackgroundHeading">G.M. Torreblanca</div>
				{blurbTitle && <>
					<div className="BackgroundSlogan" onClick={setBlurbActive_true}>{blurbTitle}</div>
					<div className="BackgroundBlurbContainer" onClick={setBlurbActive_false}>
						<div className="BackgroundBlurbTitle">{blurbTitle}</div>
						<div className="BackgroundBlurb" dangerouslySetInnerHTML={{ __html: blurbHtml }} />
					</div>
				</>
				}
			</div>
			<div className="BackgroundShieldContainer">
				<BackgroundShield />
			</div>
		</div>
	)
}
