import React from 'react'

export default class extends React.Component {
	componentDidMount () {
		const input = document.querySelector(`[name=${this.props.name}]`)
		input && input.focus()
	}

	render () {
		return <div children={this.props.children} />
	}
}
