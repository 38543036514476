import _axios from 'axios'

export function disableScroll () {
	document.body.classList.add('disable-scroll')
}

export function enableScroll () {
	document.body.classList.remove('disable-scroll')
}

const _stops = [
	{ key: 'plaza_america', name: 'Plaza América', abbrev: 'PA' },
	{ key: 'campillin', name: 'El Campillín', abbrev: 'C' },
	{ key: 'rio_caudal', name: 'Río Caudal, 6', abbrev: 'RC' }
]

const _stopsIndex = _stops.reduce(function (memo, stop) {
	memo[stop.key] = stop
	return memo
}, {})

const _times = {
	A: ['7:10', '7:15', '7:20'],
	B: ['7:25', '7:30', '7:35'],
	C: ['7:55', '8:00', '8:05'],
	D: ['8:25', '8:30', '8:35'],
	E: ['10:25', '10:30', '10:35'],
	H: ['15:55', '16:00', '16:05'],
	K: ['7:10', '7:15', '7:20'],
	L: ['7:25', '7:30', '7:35'],
	N: ['7:55', '8:00', '8:05'],
	P: ['8:25', '8:30', '8:35'],
	S: ['10:25', '10:30', '10:35'],
	T: ['15:55', '16:00', '16:05'],
}

export function stopsWithTimes (schedule, scheduleOffset) {
	const stops = _stops.slice(0)
	const times = _times[schedule]

	if (!times) return [] // this can happen if it's not a standard schedule.

	if (schedule.charCodeAt(0) >= 'K'.charCodeAt(0)) stops.reverse()

	return stops.map(function (stop, idx) {
		let time = applyScheduleOffset(times[idx], scheduleOffset)
		return { stop, time }
	})
}

function applyScheduleOffset (time, offset) {
	if (!offset) return time

	let [h, m] = time.split(':')
	let neg = offset < 0
	let ho = Math.floor(Math.abs(offset) / 60)
	let mo = offset % 60

	h = parseInt(h, 10) + (neg ? -1 * ho : ho)
	m = parseInt(m, 10) + (neg ? -1 * mo : mo)
	while (m < 0) {
		h = h - 1
		m = m + 60
	}
	while (m >= 60) {
		h = h + 1
		m = m - 60
	}
	if (h < 0) {
		h = h + 24
	}
	if (h > 23) {
		h = h - 24
	}

	let ms = '' + m
	if (ms.length < 2) {
		while (ms.length < 2) ms = '0' + ms
	}

	return '' + h + ':' + ms
}

export function getStop (key) {
	return _stopsIndex[key]
}

export function getTime (schedule, scheduleOffset, stopKey) {
	return stopsWithTimes(schedule, scheduleOffset).filter(({ stop }) => (stop.key === stopKey))[0].time
}


function extractCsrfTokenFromDoc (doc) {
	return (doc.querySelector('[name="csrf-token"]') || {content: 'no-csrf-token'}).content
}

export function extractCsrfToken (htmlDocumentString) {
	const parser = new DOMParser()
	const doc = parser.parseFromString(htmlDocumentString, 'text/html')
	return extractCsrfTokenFromDoc(doc)
}

export const axios = _axios.create({
	headers: {
		common: {
			'X-CSRF-Token': extractCsrfTokenFromDoc(document)
		}
	}
})


export const mideDescriptions = {
	'Severidad del medio': {
		1: 'El medio no está exento de riesgos',
		2: 'Hay más de un factor de riesgo',
		3: 'Hay varios factores de riesgo',
		4: 'Hay bastantes factores de riesgo',
		5: 'Hay muchos factores de riesgo'
	},
	'Dificultad de orientación': {
		1: 'Caminos y cruces bien definidos',
		2: 'Sendas o señalización que indica la continuidad',
		3: 'Exige identificación precisa de accidentes geográficos y puntos cardinales',
		4: 'Exige técnicas de orientación y navegación fuera de traza',
		5: 'La navegación es interrumpida por obstáculos que hay que bordear'
	},
	'Dificultad de desplazamiento': {
		1: 'Marcha por superficie lisa',
		2: 'Marcha por caminos de herradura',
		3: 'Marcha por sendas escalonadas o terrenos irregulares',
		4: 'Es preciso el uso de las manos para mantener el equilibrio',
		5: 'Requiere pasos de escalada para la progresión'
	},
	'Esfuerzo necesario': {
		1: 'Hasta 1 h de marcha efectiva',
		2: 'Más de 1 h y hasta 3 h de marcha efectiva',
		3: 'Más de 3 h y hasta 6 h de marcha efectiva',
		4: 'Más de 6 h y hasta 10 h de marcha efectiva',
		5: 'Más de 10 h de marcha efectiva'
	}
}
