import React from 'react'

export default class extends React.Component {
	render () {
		return (
			<div className="ViewContainer">
				<div className="Contacto">
					<p>Plaza del Sol, Nº 1, 1º - 33009 Oviedo</p>
					<p>GPS (UTM): 30T 269635 4804972</p>
					<p>Teléfono: 636 004 487</p>
					<p>Horario de apertura: viernes de 20:00 a 22:00</p>
					<p>Email: <a className="Link" href="mailto:grupo@gmtorreblanca.com">grupo@gmtorreblanca.com</a></p>
					<p>Facebook: <a className="Link" href="https://www.facebook.com/grupo.montanatorreblancaoviedo" target="_blank">grupo.montanatorreblancaoviedo</a></p>
					<p>Instagram: <a className="Link" href="https://www.instagram.com/g.m.torreblanca/" target="_blank">g.m.torreblanca</a></p>
				</div>
			</div>
		)
	}
}
