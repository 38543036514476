import React from 'react'

export const ApplicationContext = React.createContext({
	admin: false,
	setAdmin: () => {},
	csrfToken: null,
	setCsrfToken: () => {},
	officeIp: null,
	setOfficeIp: () => {},
	options: {},
	setOptions: () => {}
})
