import React, { useEffect, useState } from 'react'
import { parseISO } from 'date-fns'
import { axios } from '../helpers'

export default function Evento (props) {
	const activityId = props.match.params.activity_id

	const [ isLoaded, setIsLoaded ] = useState(false)
	const [ error, setError ] = useState(null)
	const [ activity, setActivity ] = useState(null)
	const [ links, setLinks ] = useState({})

	useEffect(() => {
		axios.get(`/actividades/${activityId}.json`).then(function (res) {
			const { activity, links } = res.data
			activity.starts_at = parseISO(activity.starts_at)
			activity.ends_at = parseISO(activity.ends_at)
			activity.inscription_opens_at = parseISO(activity.inscription_opens_at)
			activity.inscription_closes_at = parseISO(activity.inscription_closes_at)
			setActivity(activity)
			setLinks(links)
			setIsLoaded(true)
		}).catch(function (error) {
			setError(error)
			setIsLoaded(true)
		})
	}, [ activityId ])

	if (!isLoaded) {
		return <span>Not yet loaded.</span>
	} else if (error) {
		return <span>Error: {error}</span>
	} else {
		return (
			<div className="TextContainer">
				{ links.promo && (
					<div className="EventPromoContainer">
						<img src={links.promo} width='100%' />
					</div>
				)}
				{ !links.promo && (<>
					<h2>{activity.name}</h2>
					<div dangerouslySetInnerHTML={{__html: activity.description}} />
				</>)}
			</div>
		)
	}
}
