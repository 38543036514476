import React from 'react'

class Tarifas extends React.Component {
	render () {
		return (
			<div className="ViewContainer TarifasContainer">
				<h1 className="Title TarifasTitle">Tarifas 2024</h1>
				<hr className="TarifasDivider" />

				<h2>Salidas de un día</h2>
				<p>INFANTIL &mdash; 4/5 EUR</p>
				<p>JUVENIL &mdash; 6/10 EUR</p>
				<p>ADULTO &mdash; 12/17 EUR</p>

				<h2>Alquiler material</h2>
				<p>CRAMPONES &mdash; 3/5 EUR</p>
				<p>PIOLET &mdash; 2/4 EUR</p>

				<h2>Cuotas del Club para 2024</h2>
				<p>INFANTIL &mdash; 10 EUR</p>
				<p>JUVENIL &mdash; 15 EUR</p>
				<p>ADULTO &mdash; 30 EUR</p>

				<hr className="TarifasDivider" />
				<ul className="TarifasNotes">
					<li>Precios son para socios / no socios.</li>
					<li>Se alquila material solo para salidas del grupo.</li>
					<li>INFANTIL menos de 13 años.</li>
					<li>JUVENIL menos de 26 años.</li>
				</ul>
			</div>
		)
	}
}

export default Tarifas
