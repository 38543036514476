import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import NavigationMenu from './components/navigation_menu'
import Portada from './components/portada'
import Salida from './components/salida'
import Evento from './components/evento'
import Programacion from './components/programacion'
import HistorialDeSalidas from './components/historial_de_salidas'
import Tarifas from './components/tarifas'
import Media from './components/media';
import Contacto from './components/contacto'
import Directiva from './components/directiva'
import Historia from './components/historia'
import AvisoLegal from './components/aviso_legal'
import Admin from './components/admin'
import AdminSignin from './components/admin/signin'

import { ApplicationContext } from './components/contexts';

const token = document.querySelector('[name="csrf-token"]') || { content: 'no-csrf-token' };

function Application() {
	const [admin, setAdmin] = useState(document.gmt.admin);
	const [csrfToken, setCsrfToken] = useState(token.content);
	const [officeIp, setOfficeIp] = useState(document.gmt.officeIp);
	const [options, setOptions] = useState({ showBorrado: false });

	return (
		<ApplicationContext.Provider value={{ admin, setAdmin, csrfToken, setCsrfToken, officeIp, setOfficeIp, options, setOptions }}>
			<Router>
				<div>
					<NavigationMenu />
					<Switch>
						<Route exact path="/" component={Portada} />
						<Route path="/salidas/:activity_id" component={Salida} />
						<Route path="/eventos/:activity_id" component={Evento} />
						<Route path="/programacion/:year" component={Programacion} />
						<Route path="/historial" component={HistorialDeSalidas} />
						<Route path="/tarifas" component={Tarifas} />
						<Route exact path="/media" component={Media} />
						<Route path="/contacto" component={Contacto} />
						<Route path="/directiva" component={Directiva} />
						<Route path="/historia" component={Historia} />
						<Route path="/legal" component={AvisoLegal} />
						<Route exact path="/admin" component={Admin} />
						<Route path="/admin/signin" component={AdminSignin} />
					</Switch>
				</div>
			</Router>
		</ApplicationContext.Provider>
	);
}

document.addEventListener('DOMContentLoaded', () => {
	ReactDOM.render(
		<Application />,
		document.body.appendChild(document.createElement('div')),
	);
});
