import React from 'react';

const youtubeVideoIds = [
	'cV-Z_XDloO0', // Somos Torreblanca
	'HahdoSRym-Q', // Cazorla
	'g0ECkKsqe0k', // Ubiñas
]

const albums = [
	{
		name: 'GR-20 Córcega',
		url: 'https://photos.app.goo.gl/vE2yXd5zA4eQNsbt9',
		thumbnail_src: '/gr20.jpg'
	},
	{
		name: 'Salidas Invernales',
		url: 'https://photos.app.goo.gl/pJVk3xkrSegZZ1A17',
		thumbnail_src: '/invernal.jpg'
	},
	{
		name: 'Salidas Primavera-Otoño',
		url: 'https://photos.app.goo.gl/kFPKw7RJgpiLaTZ17',
		thumbnail_src: '/primavera_otono.jpg'
	}
];

export default () => (
	<div className="ViewContainer MediaContainer">
		<h1 className="Title MediaTitle">Foto y Vídeo</h1>
		<hr className="Divider" />

		<div>
			<div>
				{albums.map((album, idx) => (
					<div key={idx} className="AlbumLinkContainer">
						<p><strong>{album.name}</strong></p>
						<a href={album.url} target="_blank">
							<img src={album.thumbnail_src} />
						</a>
					</div>
				))}
			</div>
			<div>
				{youtubeVideoIds.map((videoId) => (
					<iframe key={videoId} className="VideoContainer" src={`https://www.youtube.com/embed/${videoId}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
				))}
			</div>
		</div>
	</div>
);
