import React from 'react'
import { axios, disableScroll, enableScroll, stopsWithTimes } from '../helpers'
import { ApplicationContext } from '../contexts';

class ListaDeAsistentes extends React.Component {

	state = {
		participants: null,
		isLoaded: false,
		error: null
	}

	handleESC = (event) => {
		if (event.keyCode === 27) this.props.onClose()
	}

	componentDidMount () {
		disableScroll()
		document.addEventListener('keydown', this.handleESC)
		this.syncStateFromServer()
	}

	componentWillUnmount () {
		enableScroll()
		document.removeEventListener('keydown', this.handleESC)
	}

	syncStateFromServer = () => {
		const self = this
		axios.get(`/actividades/${this.props.activity.id}/asistentes.json`).then(function (res) {
			const { participants } = res.data
			if (participants) {
				self.setState({ isLoaded: true, participants })
			} else {
				self.setState({ isLoaded: true, error: new Error(res.data.error) })
			}
		}).catch(function (error) {
			self.setState({ isLoaded: true, error })
		})
	}

	dontPropagate = (event) => {
		event.stopPropagation()
	}

	onShowBorradoChange = (event) => {
		this.context.setOptions({
			...this.context.options,
			showBorrado: event.target.checked
		})
	}

	onShowExcursionChange = (event) => {
		this.context.setOptions({
			...this.context.options,
			showExcursionOnly: event.target.checked
		})
	}

	borrarParticipant = (id) => {
		const self = this
		return function () {
			axios.delete(`/actividades/${self.props.activity.id}/asistentes/${id}`).then(function (res) {
				self.setState({ participants: res.data.participants })
			}).catch(function (error) {
				self.setState({ error })
			})
		}
	}

	reapuntarParticipant = (id) => {
		const self = this
		return function () {
			axios.patch(`/actividades/${self.props.activity.id}/asistentes/${id}`, { borrado: false }).then(function (res) {
				self.setState({ participants: res.data.participants })
			}).catch(function (error) {
				self.setState({ error })
			})
		}
	}

	confirmarEliminarParticipants = () => {
		const self = this
		if (window.confirm('¿Quieres eliminar todas las asistentes?')) {
			axios.delete(`/actividades/${self.props.activity.id}/asistentes/destroy_all_forever`).then(function (res) {
				self.setState({ participants: res.data.participants })
			}).catch(function (error) {
				self.setState({ error })
			})
		}
	}

	handleParadaChange = (id) => {
		const self = this
		return function (event) {
			const parada = event.target.value
			axios.patch(`/actividades/${self.props.activity.id}/asistentes/${id}`, { parada }).then(function (res) {
				self.setState({ participants: res.data.participants })
			}).catch(function (error) {
				self.setState({ error })
			})
		}
	}

	handleAttendingChange = (id) => {
		const self = this
		return function (event) {
			const attending = event.target.value
			axios.patch(`/actividades/${self.props.activity.id}/asistentes/${id}`, { attending }).then(function (res) {
				self.setState({ participants: res.data.participants })
			}).catch(function (error) {
				self.setState({ error })
			})
		}
	}

	render () {
		const { admin, options } = this.context
		const { activity } = this.props
		const { participants, isLoaded, error } = this.state

		if (!isLoaded) return null

		let filteredParticipants = participants
		if (!options.showBorrado) {
			filteredParticipants = participants.filter(function (participant) {
				return !participant.borrado
			})
		}
		if (options.showExcursionOnly) {
			filteredParticipants = filteredParticipants.filter(function (participant) {
				return participant.attending !== 'solo_fiesta'
			})
		}

		return (
			<div className="AsistentesContainer" onClick={admin ? null : this.props.onClose}>
				<div className="AsistentesScrollContainer">
					{admin && (
						<div className="AsistentesControlsContainer">
							<div className="AsistentesFilters">
								<label>
									<input type="checkbox" checked={!!options.showBorrado} onChange={this.onShowBorradoChange} />
									mostrar borrado?
								</label>
								{activity.attending_options.length && (
									<label>
										<input type="checkbox" checked={!!options.showExcursionOnly} onChange={this.onShowExcursionChange} />
										solo excursión?
									</label>
								)}
							</div>
							<button className="AsistentesCloseButton" onClick={this.props.onClose} />
						</div>
					)}
					{!error && admin && (<>
						<table className="AsistentesTable" style={{width: '100%'}}>
							<thead>
								<tr>
									<th>#</th>
									<th>Apellidos y nombre</th>
									<th>Alias</th>
									<th>DNI</th>
									<th>Teléfono</th>
									<th style={{textAlign: 'center'}}>Parada</th>
									<th style={{textAlign: 'center'}}>Socio?</th>
									<th>Opciones</th>
									<th>&nbsp;</th>
								</tr>
							</thead>
							<tbody>
								{filteredParticipants.map((participant, idx) => {
									return <tr className="AsistenteRow" key={idx}>
										<td>{idx+1}</td>
										<td className={participant.borrado ? "AsistenteBorrado" : undefined}>{participant.full_name}</td>
										<td className={participant.borrado ? "AsistenteBorrado" : undefined}>{participant.nickname}</td>
										<td className={participant.borrado ? "AsistenteBorrado" : undefined}>{participant.dni}</td>
										<td className={participant.borrado ? "AsistenteBorrado" : undefined}>{participant.phone}</td>
										<td className={participant.borrado ? "AsistenteBorrado" : undefined} style={{textAlign: 'center'}}>
											{participant.attending !== 'solo_fiesta' && (
												<select value={participant.parada} onChange={this.handleParadaChange(participant.id)} disabled={participant.borrado}>
													<option key="unknown" value="" />
													{stopsWithTimes(activity.horario, activity.horario_offset).map(({ stop }) => {
														return <option key={stop.key} value={stop.key}>{stop.abbrev}</option>
													})}
												</select>
											)}
										</td>
										<td className={participant.borrado ? "AsistenteBorrado" : undefined} style={{textAlign: 'center'}}>{participant.socio ? '•' : ''}</td>
										<td className={participant.borrado ? "AsistenteBorrado" : undefined}>
											{participant.attending && (
												<select value={participant.attending} onChange={this.handleAttendingChange(participant.id)} disabled={participant.borrado}>
													{activity.attending_options.map(([ label, key ]) => {
														return <option key={key} value={key}>{label}</option>
													})}
												</select>
											)}
										</td>
										<td>
											{!participant.borrado && (
												<button onClick={this.borrarParticipant(participant.id)}>borrar</button>
											)}
											{participant.borrado && (
												<button onClick={this.reapuntarParticipant(participant.id)}>reapuntar</button>
											)}
										</td>
									</tr>
								}, this)}
							</tbody>
						</table>
						<a href={`/actividades/${activity.id}/asistentes.csv`} target="_blank" className="LinkAsistentesCSV">Descargar CSV</a>
						{JSON.stringify(activity.ends_at) < JSON.stringify(new Date()) && (<>
							<br />
							<button onClick={this.confirmarEliminarParticipants}>Eliminar todas las participantes.</button>
						</>)}
					</>)}
					{!error && !admin && (
						<ol className="AsistentesList" onClick={this.dontPropagate}>
							{participants.map((participant, idx) => {
								return <li className="AsistenteListItem" key={idx}><span>{participant.nickname}</span></li>
							})}
						</ol>
					)}
					{error && (
						<span>{error.message}</span>
					)}
				</div>
			</div>
		)
	}
}

ListaDeAsistentes.contextType = ApplicationContext

export default ListaDeAsistentes
