import React from 'react';
import { parseISO, format, isSaturday } from 'date-fns';
import * as esLocale from 'date-fns/locale/es';
import ReactTooltip from 'react-tooltip';
import ElevationPlot from '../elevation_plot';
import Inscripcion from '../inscripcion';
import ListaDeAsistentes from '../lista_de_asistentes';
import { axios, stopsWithTimes, mideDescriptions } from '../helpers';
import { formatDistance } from 'date-fns/esm';
import { ApplicationContext } from '../contexts';

class Salida extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoaded: false,
			error: null,
			containerWidth: null,
			showSignup: false,
			showListaDeAsistentes: false
		};

		this.containerRef = React.createRef();
		this.lastUpdatedContainerWidth = null;
	}

	updateContainerWidth = () => {
		if (!this.containerRef.current) return;

		if (this.lastUpdatedContainerWidth && (new Date().getTime() - this.lastUpdatedContainerWidth < 500)) {
			if (this.updateContainerWidthTimer) clearTimeout(this.updateContainerWidthTimer);
			this.updateContainerWidthTimer = setTimeout(this.updateContainerWidth, 501);
			return;
		}

		const containerWidth = this.containerRef.current.offsetWidth;
		if (this.state.containerWidth !== containerWidth) {
			this.setState({ containerWidth });
			this.lastUpdatedContainerWidth = new Date().getTime();
		}
	};

	componentDidMount() {
		this.syncStateFromServer();
		window.addEventListener('resize', this.updateContainerWidth);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateContainerWidth);
	}

	componentDidUpdate() {
		this.updateContainerWidth();
	}

	syncStateFromServer = () => {
		const self = this;
		axios.get(`/actividades/${this.props.match.params.activity_id}.json`)
			.then(function (res) {
				const { activity, links } = res.data;
				if (activity) {
					activity.starts_at = parseISO(activity.starts_at);
					activity.ends_at = parseISO(activity.ends_at);
					activity.inscription_opens_at = parseISO(activity.inscription_opens_at);
					activity.inscription_closes_at = parseISO(activity.inscription_closes_at);
					self.setState({ isLoaded: true, activity, links });
				} else {
					self.setState({ isLoaded: true, error: new Error(res.data.error) });
				}
			})
			.catch(function (error) {
				self.setState({ isLoaded: true, error });
			});
	};

	showSignup = (event) => {
		event.preventDefault();
		event.stopPropagation();

		this.setState({ showSignup: true });
	};

	onSignupComplete = () => {
		this.setState({ showSignup: false });
		this.syncStateFromServer();
	};

	showListaDeAsistentes = (event) => {
		event.preventDefault();
		event.stopPropagation();

		this.setState({ showListaDeAsistentes: true });
	};

	onCloseListaDeAsistentes = () => {
		this.setState({ showListaDeAsistentes: false });
	};

	render() {
		const { admin } = this.context;
		const { error, isLoaded, activity, links, showSignup, showListaDeAsistentes } = this.state;
		let content;

		if (error) {
			content = <div className="ErrorContainer">Error: {error.message}</div>;
		} else if (!isLoaded) {
			content = <span>&nbsp;</span>;
		} else {
			content = (<>
				{showSignup && <Inscripcion activity={activity} onComplete={this.onSignupComplete} />}
				{showListaDeAsistentes && <ListaDeAsistentes activity={activity} onClose={this.onCloseListaDeAsistentes} />}

				<div ref={this.containerRef} className="SalidaContainer">
					<ReactTooltip className="ReactTooltip" />

					<div className="Heading">
						{this.state.containerWidth && activity.elevation_plot_data && (
							<div className="ElevationPlot">
								<ElevationPlot data={activity.elevation_plot_data} distance={activity.distance} {...this.elevationPlotDimensions({ top: false })} />
							</div>
						)}

						{activity.blurb && activity.blurb.length > 0 && (
							<div className="Blurb" dangerouslySetInnerHTML={{ __html: activity.blurb }} />
						)}

						{activity.inscription_open && (
							<div className="ApuntateContainer">
								<span className="InscriptionCloseContainer">
									cierre de inscripciones en <span className="InscriptionClosesTimeDescription" data-tip={this.formatTime(activity.inscription_closes_at)} data-place="bottom" data-effect="solid">{formatDistance(new Date(), activity.inscription_closes_at, { locale: esLocale, awareOfUnicodeTokens: true })}</span>
								</span>
								<a href="#" onClick={this.showSignup} className="ApuntateButton">Apúntate</a>
							</div>
						)}
						{(!activity.inscription_open && activity.publicado && activity.inscription_opens_at < new Date() && activity.inscription_closes_at > new Date()) && (
							<div className="ApuntateContainer">
								<span className="InscriptionCloseContainer"><span className="InscriptionClosedMessage">inscripción cerrada por límite de plazas</span></span>
							</div>
						)}
						{(!activity.inscription_open && activity.publicado && activity.inscription_opens_at > new Date()) && (
							<div className="ApuntateContainer">
								<span className="InscriptionCloseContainer"><span className="InscriptionOpeningMessage">se abre la inscripción en <span className="InscriptionOpensTimeDescription" data-tip={this.formatTime(activity.inscription_opens_at)} data-place="bottom" data-effect="solid">{formatDistance(new Date(), activity.inscription_opens_at, { locale: esLocale, awareOfUnicodeTokens: true })}</span></span></span>
							</div>
						)}
						{(!activity.inscription_open && activity.publicado && activity.inscription_closes_at < new Date()) && (
							<div className="ApuntateContainer">
								<span className="InscriptionCloseContainer"><span className="InscriptionClosedMessage">inscripción cerrada</span></span>
							</div>
						)}
						{!activity.publicado && (
							<div className="ApuntateContainer">
								<span className="InscriptionCloseContainer"><span className="InscriptionClosedMessage">inscripción cerrada</span></span>
							</div>
						)}

						<p className="BusSchedule">{this.formatBusSchedule(activity.horario, activity.horario_offset)}</p>

						<p className={`ActivityDate ${isSaturday(activity.starts_at) ? "Saturday" : undefined}`}>
							{this.formatDate(activity.starts_at)}
						</p>

						<h1 className={`ActivityName ${activity.inscription_open ? "ActivityNameInscriptionOpen" : undefined}`} dangerouslySetInnerHTML={{ __html: activity.name }} />

						{activity.related_activity_ids.length > 0 && (
							<div className="RelatedActivities">
								{activity.related_activity_ids.map((id, idx) => {
									return <span key={idx} className="RelatedActivity">
										{activity.id === id && `día ${idx + 1}`}
										{activity.id !== id && <a href={`/salidas/${id}`}>día {idx + 1}</a>}
									</span>;
								})}
							</div>
						)}
					</div>

					<div className="ActivityVideoAndSummary">
						<div className="ActivityVideo">
							{activity.youtube_video_id != null && (
								<iframe {...this.youtubeIframeDimensions()} src={`https://www.youtube.com/embed/${activity.youtube_video_id}?rel=0`} frameborder="0" allowFullScreen></iframe>
							)}
						</div>

						<div className="ActivitySummaryContainer">
							<p className="ActivitySummary">
								{this.renderSummary(activity, links)}
							</p>

							<p className="Cartography">
								{activity.cartography}
							</p>

							<div className="ActivityMIDEContainer">
								<div className="ActivityMIDE">
									<ul>
										{this.renderMideListItem('Severidad del medio', activity.mide_medio)}
										{this.renderMideListItem('Dificultad de orientación', activity.mide_itinerario)}
										{this.renderMideListItem('Dificultad de desplazamiento', activity.mide_desplazamiento)}
										{this.renderMideListItem('Esfuerzo necesario', activity.mide_esfuerzo)}
									</ul>
								</div>
							</div>
							<div className="ActivityMIDENotes">{activity.considerations}</div>
						</div>
					</div>

					{this.state.containerWidth && activity.elevation_plot_data && false && (
						<div className="ElevationPlot">
							<ElevationPlot data={activity.elevation_plot_data} distance={activity.distance} {...this.elevationPlotDimensions({ top: false })} />
						</div>
					)}

					<div className="ActivityLinks">
						<ul>
							{admin && (
								<li><a href={`/actividades/${activity.id}/edit`}>editar</a></li>
							)}
							<li><a href={links.track}>track</a></li>
							<li><a href={links.print} target="_blank">imprimir</a></li>
							<li><a href={'#'} onClick={this.showListaDeAsistentes}>lista de asistentes</a></li>
						</ul>
					</div>

					<div className="ActivityMIDEContainerCompact">
						<div className="ActivityMIDE">
							<ul>
								{this.renderMideListItem('Severidad del medio', activity.mide_medio)}
								{this.renderMideListItem('Dificultad de orientación', activity.mide_itinerario)}
								{this.renderMideListItem('Dificultad de desplazamiento', activity.mide_desplazamiento)}
								{this.renderMideListItem('Esfuerzo necesario', activity.mide_esfuerzo)}
							</ul>
						</div>
					</div>
					<div className="ActivityMIDENotesCompact">{activity.considerations}</div>

					<div className="DescriptionContainer">
						<div className="ActivityDescription" dangerouslySetInnerHTML={{ __html: this.paragraphs(activity.description) }} />
					</div>

					<div className="ActivityNote">
						<p>El Grupo de Montaña Torreblanca realiza excursiones de diversa dificultad, no estando todas ellas al alcance de cualquier persona. En la montaña cada uno es responsable de sí mismo, por lo que debe de informarse con antelación sobre la dificultad de la ruta a realizar y valorar si está dentro de sus posibilidades, así como acudir siempre con el equipamiento necesario en función de las condiciones meteorológicas.</p>
						<p>En las excursiones en las que pueda haber nieve o hielo es obligatorio llevar piolet y crampones, y conocer su manejo.</p>
						<p>Además cada participante deberá llevar linterna y manta térmica en todas las salidas de la temporada.</p>
					</div>
				</div>
			</>);
		}

		return (
			<div className="ViewContainer">{content}</div>
		);
	}

	paragraphs = (text) => {
		return text.split("\n").map( l => "<p>" + l + "</p>").join("\n")
	}

	renderSummary = (activity, links) => {
		return [
			this.formatDistance(activity.distance),
			this.formatElevationChange(activity.elevation_gain),
			this.formatElevationChange(-activity.elevation_loss),
			this.formatDuration(activity.duration),
			this.formatType(activity.excursion_type || 'travesía' || '?')
		].filter(Boolean).join(' / ');
	};

	renderMideListItem = (label, value) => {
		return (
			<li key={label}>
				<span className="Label">{label}:</span>
				<span className="ValueList">
					<span className={`Value One ${value === 1 ? "ActiveValue" : undefined}`} data-tip={this.mideTooltipText(label, 1)}>1</span>
					<span className={`Value Two ${value === 2 ? "ActiveValue" : undefined}`} data-tip={this.mideTooltipText(label, 2)}>2</span>
					<span className={`Value Three ${value === 3 ? "ActiveValue" : undefined}`} data-tip={this.mideTooltipText(label, 3)}>3</span>
					<span className={`Value Four ${value === 4 ? "ActiveValue" : undefined}`} data-tip={this.mideTooltipText(label, 4)}>4</span>
					<span className={`Value Five ${value === 5 ? "ActiveValue" : undefined}`} data-tip={this.mideTooltipText(label, 5)}>5</span>
				</span>
			</li>
		);
	};

	mideTooltipText = (label, value) => {
		return mideDescriptions[label][value];
	};

	formatTime = (time) => {
		return format(time, "eeee',' d 'de' MMMM 'a las' HH'h'", { locale: esLocale, awareOfUnicodeTokens: true });
	};

	formatDate = (date) => {
		return format(date, "eeee',' d 'de' MMMM 'de' YYYY", { locale: esLocale, awareOfUnicodeTokens: true });
	};

	formatDistance = (distance_in_meters) => {
		return `${(distance_in_meters / 1000).toFixed(1)} km`;
	};

	formatElevationChange = (meters) => {
		return `${meters > 0 ? '+' : '-'}${Math.abs(meters)}m`;
	};

	formatDuration = (duration_in_seconds) => {
		const h = duration_in_seconds / 3600;
		const m = (duration_in_seconds - (h * 3600)) / 60;
		const mm = m / 6 === 0 ? '' : `,${m / 6}`;
		const unit = h === 1 && mm === '' ? ' hora' : ' horas';
		return `${h}${mm}${unit}`;
	};

	formatType = (excursionType) => {
		return excursionType;
	};

	formatBusSchedule = (schedule, scheduleOffset) => {
		return (
			<>{
				stopsWithTimes(schedule, scheduleOffset).map(function ({ stop, time }) {
					return <span key={stop.abbrev}><label>{stop.name}:</label> <time>{time}</time></span>;
				})
			}</>
		);
	};

	elevationPlotDimensions = ({ top }) => {
		let width = this.state.containerWidth;
		let height = 80;

		if (top) {
			width = width - 100;
			height = 30;
		}

		return { width, height };
	};

	youtubeIframeDimensions = () => {
		let width = this.state.containerWidth;
		let height = width * 0.75;

		if (this.state.containerWidth > 735) {
			width = 420;
			height = 315;
		}

		return { width, height };
	};
}

Salida.contextType = ApplicationContext;

export default Salida;
