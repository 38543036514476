import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { ApplicationContext } from '../contexts'
import { extractCsrfToken } from '../helpers'

export default function AdminSignin () {
	const [ email, setEmail ] = useState('')
	const [ password, setPassword ] = useState('')
	const [ rememberMe, setRememberMe] = useState(false)
	const [ error, setError ] = useState(null)
	const { admin, setAdmin, csrfToken, setCsrfToken } = useContext(ApplicationContext)

	function onSubmit (event) {
		event.preventDefault()

		axios({
			method: 'post',
			url: '/users/sign_in',
			data: {
				user: {
					email,
					password,
					remember_me: rememberMe ? '1' : '0'
				}
			},
			headers: {
				'X-CSRF-Token': csrfToken,
				'Accept': 'application/json, text/html'
			}
		}).then((res) => {
			setCsrfToken(extractCsrfToken(res.data))
			setAdmin(true)
		}).catch((error) => {
			if (error.response.status === 401) {
				setError(new Error('Pruébate otra vez ...'))
			} else {
				setError(error)
			}
		})

		return false
	}

	function onEmailChange (event) {
		setEmail(event.target.value)
	}

	function onPasswordChange (event) {
		setPassword(event.target.value)
	}

	function onRememberMeChange (event) {
		setRememberMe(event.target.checked)
	}

	if (admin) {
		return <Redirect exact to="/admin" />
	}

	return (
		<div className="AdminContainer SigninContainer">
			<form className="SigninForm" onSubmit={onSubmit}>
				<h2>Iniciar sesión</h2>
				{ error && <p className="ErrorContainer">{error.message}</p> }
				<p>
					<label htmlFor="email">Email</label>
					<input type="email" name="email" id="email" value={email} onChange={onEmailChange} />
				</p>
				<p>
					<label htmlFor="password">Contraseña</label>
					<input type="password" name="password" value={password} onChange={onPasswordChange} />
				</p>
				<p>
					<input type="checkbox" name="remember_me" id="remember_me" checked={rememberMe} onChange={onRememberMeChange} />
					<label htmlFor="remember_me">Recordarme</label>
				</p>
				<p>
					<input type="submit" value="Iniciar sesión" />
				</p>
			</form>
		</div>
	)
}
