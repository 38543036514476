import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import { ApplicationContext } from '../contexts'
import { extractCsrfToken } from '../helpers'

export default function Admin () {
	const { admin, setAdmin, csrfToken, setCsrfToken, officeIp } = useContext(ApplicationContext)

	if (!admin) {
		return <Redirect to="/admin/signin" />
	}

	function onSignout (event) {
		event.preventDefault()

		axios({
			method: 'post',
			url: '/users/sign_out',
			data: '_method=DELETE',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'X-CSRF-Token': csrfToken
			}
		}).then((res) => {
			setCsrfToken(extractCsrfToken(res.data))
			setAdmin(false)
		})

		return false
	}

	return (
		<div className="AdminContainer">
			<a href={`/actividades/new`}>+ Nueva Actividad</a>
			<br></br>
			<a href={`/albums`}>Gestionar Álbumes</a>
			<br></br>
			<form className="SignoutForm" onSubmit={onSignout}>
				<input type="submit" value="Cerrar sesión" />
			</form>
			<div className="OfficeIp">
				{officeIp}
			</div>
		</div>
	)
}
