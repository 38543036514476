import React from 'react'

export default class extends React.Component {
	render () {
		return (
			<div className="ViewContainer DirectivaContainer">
				<h1 className="Title">Directiva</h1>
				<table>
				<tbody>
				<tr>
					<td className="DirectivaCargo">Presidencia</td>
					<td>Manuel García Viejo</td>
					<td><a className="DirectivaContacto" href="mailto:presidencia@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vicepresidencia</td>
					<td>Juan Ángel Fernández Peral</td>
					<td><a className="DirectivaContacto" href="mailto:juan@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Secretaría</td>
					<td>Juan Funes Díaz</td>
					<td><a className="DirectivaContacto" href="mailto:juancho@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Tesorería</td>
					<td>Begoña Fernández Romero</td>
					<td><a className="DirectivaContacto" href="mailto:begona@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía de Seguridad</td>
					<td>Álvaro Zorzo Díez</td>
					<td><a className="DirectivaContacto" href="mailto:zorzo@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía de Formación</td>
					<td>Violeta Menéndez Suárez</td>
					<td><a className="DirectivaContacto" href="mailto:vio@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía</td>
					<td>Alberto Manuel Fernández Álvarez</td>
					<td><a className="DirectivaContacto" href="mailto:alb@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía</td>
					<td>David Fernández Fernández</td>
					<td><a className="DirectivaContacto" href="mailto:satis@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía</td>
					<td>José Luis Miguel Abian</td>
					<td><a className="DirectivaContacto" href="mailto:abian@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía</td>
					<td>Nuria Chaves Fernández</td>
					<td><a className="DirectivaContacto" href="mailto:nuria@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía</td>
					<td>Valentín Fernández Díaz</td>
					<td><a className="DirectivaContacto" href="mailto:valentin@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía</td>
					<td>Raluca Mariana Pinzari</td>
					<td><a className="DirectivaContacto" href="mailto:raluca@gmtorreblanca.com">@</a></td>
				</tr>
				<tr>
					<td className="DirectivaCargo">Vocalía</td>
					<td>Rita Lozano Marcos</td>
					<td><a className="DirectivaContacto" href="mailto:rita@gmtorreblanca.com">@</a></td>
				</tr>
				</tbody>
				</table>
			</div>
		)
	}
}
