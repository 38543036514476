import React from 'react'
import { format, isSaturday } from 'date-fns'
import * as esLocale from 'date-fns/locale/es'
import { axios, disableScroll, enableScroll, stopsWithTimes, getStop, getTime } from '../helpers'
import AutoFocus from '../auto_focus'

export default class extends React.Component {
	state = {
		error: null,
		isSocio: null,
		nombre: null,
		apellidos: null,
		alias: null,
		dni: null,
		telefono: null,
		parada: null,
		attending: null,
		canSave: false,
		saving: false,
		saved: null
	}

	componentDidMount () {
		disableScroll()
		document.addEventListener('keydown', this.handleESC)
	}

	componentWillUnmount () {
		enableScroll()
		document.removeEventListener('keydown', this.handleESC)
	}

	handleESC = (event) => {
		if (event.keyCode === 27) this.props.onComplete()
	}

	handleInputChange = (event) => {
		const target = event.target;
		const name = target.name;

		let value = target.type === 'checkbox' ? target.checked : target.value;
		if (value === 'true') value = true
		if (value === 'false') value = false

		this.setState({
			[name]: value
		});
	}

	canSave = () => {
		const { activity } = this.props
		const { nombre, apellidos, dni, telefono, parada, isSocio, attending } = this.state

		if (isSocio === null) return false
		if (activity.fiesta && !attending) return false
		if (!activity.fiesta && !parada) return false
		if (activity.fiesta && attending !== 'solo_fiesta' && !parada) return false
		if (!(nombre && apellidos && telefono)) return false
		if (!nombre.length || !apellidos.length || !telefono.length) return false
		if (isSocio === false && (!dni || !dni.length)) return false

		return true
	}

	close = (event) => {
		event.preventDefault()
		event.stopPropagation()

		this.props.onComplete()
	}

	saving = false

	save = (event) => {
		event.preventDefault()
		event.stopPropagation()

		if (this.saving) return false
		this.saving = true

		const { nombre, apellidos, telefono: phone, parada, isSocio: socio, attending } = this.state
		let { alias, dni } = this.state
		if (!alias || !alias.length) alias = nombre.split(' ')[0]
		if (!dni) dni = ''

		axios({
			method: 'post',
			url: `/actividades/${this.props.activity.id}/asistentes`,
			data: {
				participant: {
					full_name: `${apellidos}, ${nombre}`,
					alias,
					dni,
					phone,
					parada,
					socio,
					attending
				}
			},
			headers: {
				'Accept': 'application/json'
			}
		}).then((res) => {
			this.saving = false
			this.setState({ error: null, saved: res })
		}).catch((error) => {
			this.saving = false
			this.setState({ error: error })
		})
	}

	render () {
		const { activity } = this.props
		const { error, isSocio, saved } = this.state

		return (
			<div className="ViewContainer InscriptionContainer">
				<form className="InscriptionForm">
					{(error || saved) && (
						<div className="InscriptionFormHeadingContainer">
							{error && (
								<h2>{error.message}</h2>
							)}
							{!error && saved && saved.status != 200 && (
								<p><pre>{JSON.stringify(saved.data.errors, null, 2)}</pre></p>
							)}
							{!error && saved && saved.status == 200 && (
								<h1>¡Apuntad@!</h1>
							)}
						</div>
					)}

					{(!saved || saved.status != 200) && (
					<div className="InscriptionFormElements">
						<p>
							<span>¿Eres socio?</span>
							<input name="isSocio" type="radio" value="true" checked={isSocio === true} onChange={this.handleInputChange} id="soySocio" />
							<label htmlFor="soySocio" className="RadioLabel">sí</label>
							<input name="isSocio" type="radio" value="false" checked={isSocio === false} onChange={this.handleInputChange} id="noSoySocio" />
							<label htmlFor="noSoySocio" className="RadioLabel">no</label>
						</p>

						{isSocio !== null && (<AutoFocus name="nombre">
							<p>
								<label htmlFor="nombre" className="StandardLabel">Nombre:</label>
								<input type="text" name="nombre" id="nombre" onChange={this.handleInputChange} />
								{this.state.isSocio && (
									<span className="Alias">
										<label htmlFor="alias" className="StandardLabel">Alias:</label>
										<input type="text" name="alias" id="alias" onChange={this.handleInputChange} />
									</span>
								)}
							</p>
							<p>
								<label htmlFor="apellidos" className="StandardLabel">Apellidos:</label>
								<input type="text" name="apellidos" id="apellidos" onChange={this.handleInputChange} />
							</p>
							{this.state.isSocio === true && (
								<p className="AliasCompact">
									<label htmlFor="alias" className="StandardLabel">Alias:</label>
									<input type="text" name="alias" id="alias" onChange={this.handleInputChange} />
								</p>
							)}
							{this.state.isSocio === false && (
								<p>
									<label htmlFor="dni" className="StandardLabel">DNI:</label>
									<input type="text" name="dni" id="dni" onChange={this.handleInputChange} />
								</p>
							)}
							<p>
								<label htmlFor="telefono" className="StandardLabel">Teléfono:</label>
								<input type="tel" name="telefono" id="telefono" onChange={this.handleInputChange} />
							</p>
							<p>
								<label htmlFor="parada" className="StandardLabel">Parada:</label>
								<select name="parada" id="parada" required={!activity.fiesta} onChange={this.handleInputChange}>
									<option key="_" value=""></option>
									{stopsWithTimes(activity.horario, activity.horario_offset).map(({ stop, time }) => {
										return <option key={stop.key} value={stop.key} checked={this.state.parada === stop.key}>{stop.name} &ndash; {time}</option>
									})}
								</select>
							</p>

							{activity.fiesta === true && (
								<p>
									<label htmlFor="attending" className="StandardLabel">Opciones:</label>
									<select name="attending" id="attending" required={!activity.fiesta} onChange={this.handleInputChange}>
										<option key="_" value=""></option>
										{activity.attending_options.map(([ label, key ]) => {
											return <option key={key} value={key} checked={this.state.attending === key}>{label}</option>
										})}
									</select>
								</p>
							)}
						</AutoFocus>)}
					</div>
					)}

					{saved && saved.status == 200 && (
						<div className="InscriptionApuntadoContainer">
							<h2>Ruta: </h2>
							<p dangerouslySetInnerHTML={{__html: activity.name}} />
							<h2>Fecha: </h2>
							<p>{this.formatDate(activity.starts_at)}</p>
							{!!saved.data.participant.parada && (<>
								<h2>Parada: </h2>
								<p>{this.formatParada(saved.data.participant.parada)} - {this.formatTime(activity.horario, activity.horario_offset, saved.data.participant.parada)}</p>
							</>)}
							{activity.fiesta === true && (<>
								<h2>Opciones: </h2>
								<p>{this.formatAttending(saved.data.participant.attending)}</p>
							</>)}
						</div>
					)}

					<div className="InscriptionFormControls">
						{!saved && (<>
							<a href="#" onClick={this.close}>no me apunto</a>
							<button onClick={this.save} disabled={!this.canSave()}>Guardar</button>
						</>)}
						{saved && (
							<button onClick={this.close}>OK</button>
						)}
					</div>
				</form>
			</div>
		)
	}

	formatParada = (parada) => {
		return <span>{getStop(parada).name}</span>
	}

	formatAttending = (attending) => {
		const labelAndKey = this.props.activity.attending_options.find(([ label, key ]) => {
			return key === attending;
		})
		if (labelAndKey) {
			return labelAndKey[0];
		} else {
			throw new Error('Invalid attending value.');
		}
	}

	formatTime = (schedule, scheduleOffset, parada) => {
		return <span>{getTime(schedule, scheduleOffset, parada)}</span>
	}

	formatDate = (date) => {
		return <span className={isSaturday(date) ? "InscriptionIsSaturday" : undefined}>{format(date, "eeee',' d 'de' MMMM 'de' YYYY", { locale: esLocale, awareOfUnicodeTokens: true })}</span>
	}

}
